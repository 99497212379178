<template>
  <div class="page">
    <div class="container">
      <div class="card-item"
        v-for="(item, index) in guideList"
        :key="index">
        <div class="card-row">
          <div class="card-item-title" @click="openCardAction(item)">
            <span>{{ item.title }}</span>
            <div class="icon"
              :class="{ open: item.isOpen }">
              <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20231218102539568-arrow-icon.png" alt="" />
            </div>
          </div>
          <div class="card-item-content" v-show="item.isOpen">
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="add-wx-desc" @click="jumpWxAction">有疑问？添加客服</div>
  </div>
</template>
<script>
import { GUIDE_LIST } from './tools';
import { jsJumpRouter, jsBriageEvent } from '@/utils/bridge';

export default {
  name: 'ToolsGuide',
  data() {
    return {
      guideList: GUIDE_LIST,
    };
  },
  mounted() {
    document.title = '数据功能使用手册';
    jsBriageEvent({ eventId: 'shujuchulihelp_imp' });
  },
  methods: {
    openCardAction(data) {
      this.$set(data, 'isOpen', !data.isOpen);
    },
    jumpWxAction() {
      jsJumpRouter('yyjz://app/wxapp?path=pages/ucenter/add');
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 750px;
  min-height: 100%;
  margin: auto;
  background: #F6F6F6;
  font-family: PingFangSC, PingFang SC;
}
.container {
  padding-top: 20px;
  // padding-bottom: 20px;
}
.card-item {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 0 0 0;
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 20px;
  .card-row {
    margin: 0 20px;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    padding-bottom: 32px;
  }
  &-content {
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    border-top: 2px solid rgba(0,0,0,0.04);
    padding-top: 32px;
    transition: all .5s;
    padding-bottom: 32px;
  }
  .icon {
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    transition: transform .5s;
    &.open {
      transform: rotate(0deg);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.add-wx-desc {
  font-size: 28px;
  font-weight: 400;
  color: #999999;
  line-height: 40px;
  text-decoration: underline;
  text-align: center;
  padding: 40px 0;
}
</style>
